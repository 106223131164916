import React, { useEffect, useRef, useState } from "react";
import { useCartContext } from "../../contexts/CartContext";
import AppointmentDetails from "./Components/AppointmentDetails";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import { useAuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import { usePostContext } from "../../contexts/PostContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../data/logo.png";
import CustomModal from "../../components/CustomModal";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { date } from "yup";
import moment from "moment";

const stripePromise = loadStripe(
  "pk_live_51P2FxUA28JSbW1Km2U7ys330z5uLUkfivPv6SHbkIXerz3X7opjxSKg9V19kWGUxS7Dn9AckHd0ysDVooQf31fdT00ZR9cooBj"
  // "pk_test_51P2FxUA28JSbW1KmQMSLZ6Atm0t3vYxkHsl6czGuPv4jM63MWYe6KzWfIZiguWfcNkxZsHtQTwcX61NHA8WdgTc100nwsqLr3Y"
);

const Form = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    item,
    totalPrice,
    clearCart,
    inclusiveTax,
    taxAmount,
    appliedCoupon,
    applyCoupon,
    removeCoupon,
  } = useCartContext();
  const baseUrl =
    process.env.REACT_APP_DEVELOPMENT_MODE === "DEVELOPMENT"
      ? process.env.REACT_APP_TESTING_REACT_APP_URL
      : process.env.REACT_APP_URL;
  const { user } = useAuthContext();
  const { PostAppointmentData, VerifyCoupon, CheckSlotStatus } =
    usePostContext();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    cardNumber: "",
    mm: "",
    yy: "",
    cvd: "",
  });
  const cardStyle = {
    style: {
      base: {
        iconColor: "#000",
        color: "#000",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "14px",
        // marginTop: "10px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#000",
        },
        "::placeholder": {
          color: "#000",
        },
      },
      invalid: {
        iconColor: "#000",
        color: "#000",
      },
      // marginTop: "10px",
    },
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const slotChecks = item.map(
      async ({ appointmentDetails, productDetails }) => {
        let dataToSend = {
          roomId: appointmentDetails?.selectedRoom,
          productId: productDetails?._id,
          selectedDate: appointmentDetails?.date,
          startTime: appointmentDetails?.startTime,
          endTime: appointmentDetails?.endTime,
        };
        const { success, data } = await CheckSlotStatus(dataToSend);
        console.log("🚀 ~ data:", data);
        if (!success) {
          throw new Error(
            `Product ${data?.product} with room ${data?.room} and starting time ${data?.startTime} has been already booked, Kindly remove and change it`
          );
        }
        return success;
      }
    );

    try {
      await Promise.all(slotChecks); // Wait for all slot checks to complete
      await CreatePayment(formData, totalPrice); // Proceed to payment
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message, { autoClose: false });
    }
  };

  const navigate = useNavigate();

  const CreatePayment = async (cardValues, totalPrice) => {
    setIsLoading(true);
    if (!stripe || !elements) return;
    try {
      const {
        data: { clientSecret },
      } = await axios.post(`${baseUrl}/shop/create-payment-intent`, {
        amount: inclusiveTax,
      });

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user.name,
            email: user.email,
          },
        },
      });
      if (result.error) {
        toast.error(result.error.message);
        console.error("Payment Error:", result.error);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          // Assuming item is an array of objects containing appointmentDetails
          await Promise.all(
            item.map(({ appointmentDetails }) => {
              let data = {
                ...appointmentDetails,
                isPaid: true,
                payment_result: {
                  id: result.paymentIntent.id,
                  status: "Approved",
                  email_address: user.email,
                  isRefunded: false,
                },
                price: inclusiveTax,
                discountedCoupon: appliedCoupon?._id,
              };
              PostAppointmentData(data);
            })
          );
          toast.success("Payment Success");
          clearCart();
          navigate("/profile/total-orders");
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.data?.message || "Payment Failed");
    } finally {
      setIsLoading(false);
      handleClose(); // Assuming this function is defined somewhere in your code
    }
  };

  return (
    <section className="text-black-shade body-font">
      <div className="container px-5 py-12 mx-auto">
        <div className="text-center mb-10">
          <h2 className="sm:text-3xl text-2xl font-medium title-font text-body-heading mb-4">
            Your Orders
          </h2>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto  text-opacity-80">
            Make sure to check your appointment details before payment
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-body-heading inline-flex"></div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          {item.length > 0 ? (
            item.map(({ appointmentDetails, productDetails }, i) => (
              <div
                key={i}
                className="p-4 w-full md:max-w-[50%] flex flex-col lg:flex-row text-center items-center"
              >
                <div className="w-full px-2 flex flex-col min-h-max h-full ">
                  <h2 className="text-body-heading text-4xl title-font font-medium mb-3">
                    {productDetails.name}
                  </h2>
                  <p className="text-sm px-10">{productDetails.description}</p>
                  <div className="w-full mt-2 h-full">
                    <AppointmentDetails
                      productDetails={productDetails}
                      service={appointmentDetails.serviceId}
                      room={appointmentDetails.selectedRoom}
                      date={appointmentDetails.date}
                      startTime={appointmentDetails.startTime}
                      endTime={appointmentDetails.endTime}
                      totalAmount={appointmentDetails.price}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex col-span-2 my-10 items-center justify-center w-full">
              <p className="text-2xl font-bold">Nothing in Cart.</p>
            </div>
          )}
        </div>
        {item.length > 0 && (
          <div className="flex flex-col mx-auto mt-8 border-0 py-2 px-8 focus:outline-none font-semibold rounded text-lg">
            <div className="flex justify-between mt-2 text-sm items-center">
              <p className="">Sub Total</p>
              <p className="font-semibold">
                {item.length == 0 ? "--" : `${totalPrice}$`}
              </p>
            </div>
            <div className="flex justify-between mt-2 text-sm items-center">
              <p className="">HST</p>
              <p className="font-semibold">
                {item.length == 0 ? "--" : `${taxAmount}$`}
              </p>
            </div>
            {appliedCoupon && (
              <div className="flex justify-between mt-2 text-sm items-center">
                <p className="">Coupon Discount</p>
                <p className="font-semibold">
                  {appliedCoupon.discount}
                  {appliedCoupon.type === "fixed" ? "$" : "%"}
                </p>
              </div>
            )}
            <div className="flex justify-between items-center mt-2 text-sm">
              <p className="">Total</p>
              <p className="font-semibold">
                {item.length == 0 ? "--" : `${inclusiveTax}$`}
              </p>
            </div>
            <div className="my-5 border-body-heading">
              {/* <CardElement id="card-element" options={cardStyle} />
               */}
              <div className="border-2 border-body-heading rounded-md p-4 w-full">
                <CardElement options={cardStyle} />
              </div>
              <div className="flex justify-center w-full mt-4 items-center">
                <button
                  onClick={handleSubmit}
                  className="bg-body-heading hover:bg-body-heading-hover text-white font-bold py-2 px-4 rounded mt-4"
                >
                  Pay ${inclusiveTax}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomModal open={open} handleClose={handleClose}>
        <form
          className="flex flex-col gap-3 w-full p-5 "
          onSubmit={handleSubmit}
        >
          <label className="relative w-full flex flex-col">
            <span className="font-bold mb-3">Card number</span>
            <input
              className="rounded-md peer pl-2 pr-2 py-2 border-2 border-gray-200 placeholder-gray-300"
              type="text"
              name="cardNumber"
              value={formData.cardNumber}
              onChange={handleChange}
              placeholder="0000 0000 0000"
            />
          </label>

          <label className="relative flex-1 flex flex-col">
            <span className="font-bold mb-3">Expire date</span>
            <div className="grid grid-cols-2 gap-2">
              <input
                className="rounded-md peer pl-2 pr-2 py-2 border-2 border-gray-200 placeholder-gray-300"
                type="text"
                name="mm"
                value={formData.mm}
                onChange={handleChange}
                placeholder="MM"
              />
              <input
                className="rounded-md peer pl-2 pr-2 py-2 border-2 border-gray-200 placeholder-gray-300"
                type="text"
                name="yy"
                value={formData.yy}
                onChange={handleChange}
                placeholder="YY"
              />
            </div>
          </label>

          <label className="relative flex-1 flex flex-col">
            <span className="font-bold flex items-center gap-3 mb-3">
              CVV/CVC
            </span>
            <input
              className="rounded-md peer pl-2 pr-2 py-2 border-2 border-gray-200 placeholder-gray-300"
              type="text"
              name="cvd"
              value={formData.cvd}
              onChange={handleChange}
              placeholder="•••"
            />
          </label>
          <button
            type="submit"
            disabled={isLoading ? true : false}
            className="bg-body-heading hover:bg-body-heading-hover disabled:bg-body-heading-hover text-white font-bold py-2 px-4 rounded"
          >
            {isLoading ? "Please Wait" : `Pay $${inclusiveTax}`}
          </button>
        </form>
        {/* </div> */}
      </CustomModal>
    </section>
  );
};

const Checkout = () => {
  const appearance = {
    theme: "stripe",
  };
  const options = {
    appearance,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <Form />
    </Elements>
  );
};

export default Checkout;
