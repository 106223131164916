import { createContext, useContext, useState } from "react";
import axios from "axios";
import { useAuthContext } from "./AuthContext";
import { useFetchContext } from "./FetchContext";
import { toast } from "react-toastify";
import { useAdminContext } from "./AdminContext";

const INITIAL_STATES = {
  isLoading: null,
  response: null,
  errorResponse: null,
  isError: null,
  errorMessage: "",
};

export const PostContext = createContext(INITIAL_STATES);

export const PostContextProvider = ({ children }) => {
  const [postState, setPostState] = useState(INITIAL_STATES);
  const { user, token, isAdmin, loadUser, isFrontDesk } = useAuthContext();
  const { fetchRooms, fetchProducts, fetchUserAppointments } =
    useFetchContext();
  const { fetchAllProducts, fetchCoupons, fetchWorkingHours } =
    useAdminContext();

  const baseUrl =
    process.env.REACT_APP_DEVELOPMENT_MODE === "DEVELOPMENT"
      ? process.env.REACT_APP_TESTING_REACT_APP_URL
      : process.env.REACT_APP_URL;

  const VerifyCoupon = async (formData) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.post(
        `${baseUrl}/shop/verify-coupon`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      if (error?.response?.status >= 400 && error?.response?.status <= 500) {
        setPostState({
          ...postState,
          errorMessage: error.response.data.data.error,
        });
        return toast.error(error.response.data.data.error);
      }
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const PostCouponData = async (formData) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.post(
          `${baseUrl}/admin/create-coupon`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Coupon Created");
          fetchCoupons();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const UpdateCouponData = async (formData, id) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.put(
          `${baseUrl}/admin//update-coupon/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Coupon Updated");
          fetchCoupons();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const DeleteCouponData = async (id) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.delete(
          `${baseUrl}/admin/delete-coupon/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Coupon Deleted");
          fetchCoupons();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const PostRoomData = async (formData, id) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.post(
          `${baseUrl}/admin/create-room`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Room Created");
          fetchRooms();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const UpdateRoomData = async (formData, id) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.put(
          `${baseUrl}/admin//update-room/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Room Updated");
          fetchRooms();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const DeleteRoomData = async (id) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.delete(
          `${baseUrl}/admin/delete-room/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Room Deleted");
          fetchRooms();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const PostProductData = async (
    formData,
    basicServices,
    multiSessionServices
  ) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      let serviceData = basicServices.concat(multiSessionServices);
      let dataToSend = {
        ...formData,
        services: serviceData,
        room: formData.room.map((room) => room._id),
      };
      try {
        const { data } = await axios.post(
          `${baseUrl}/admin/create-product`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Product Added");
          fetchAllProducts();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const UpdateProductData = async (
    formData,
    basicServices,
    multiSessionServices,
    id
  ) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      let serviceData = basicServices.concat(multiSessionServices);
      let dataToSend = { ...formData, services: serviceData };
      try {
        const { data } = await axios.put(
          `${baseUrl}/admin/update-product/${id}`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Product Updated");
          fetchAllProducts();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const UpdateProductandServiceStatuses = async (formData, id) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.put(
          `${baseUrl}/admin/update-product/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Product/Services Status Updated");
          fetchProducts();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const AddProductDiscount = async (formData) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.post(
          `${baseUrl}/admin/add-discount`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          toast.success("Discount Added On All Products");
          fetchAllProducts();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const RemoveProductDiscount = async () => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.put(`${baseUrl}/admin/delete-discount`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (data.success) {
          toast.success("Removed Discount on All Products");
          fetchAllProducts();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const UpdateSingleProductDiscount = async (formData, id) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.put(
          `${baseUrl}/admin/update-discount/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          toast.success("Discount Updated");
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const RemoveSingleProductDiscount = async (id) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.put(
          `${baseUrl}/admin/delete-discount/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          toast.success("Discount Removed");
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const DeleteProductData = async (id) => {
    if (isAdmin || isFrontDesk) {
      try {
        const { data } = await axios.delete(
          `${baseUrl}/admin/delete-product/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Product Deleted");
          fetchAllProducts();
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const UpdateUserAppointment = async (formData, id) => {
    if (isAdmin || isFrontDesk) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.put(
          `${baseUrl}/admin/cancel-appointment/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Appointment Updated");
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };
  const ReturnAppointmentPayment = async (formData) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.post(
        `${baseUrl}/shop/refund-payment`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (data.success) {
        toast.success("Amount Refunded");
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Refund Failed");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };

  const makeReturnPayment = async (id) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.put(`${baseUrl}/admin/make-return/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (data.success) {
        toast.success("Amount Refunded");
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Refund Failed");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const PostAppointmentData = async (formData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/user/add-appointment`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Appointment Created Successfully");
        fetchUserAppointments();
        loadUser();
        return data.message; // Return the message for better handling
      }
    } catch (error) {
      toast.error("Something went wrong, Try again in a moment");
      console.error("Post Appointment Error:", error);
      //throw error; // Re-throw the error for handling in the calling function
    }
  };
  const UpdateProfile = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.put(
        `${baseUrl}/user/update-profile`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Updated");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something went wrong, Try again in a moment");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const UpdatePassword = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.put(
        `${baseUrl}/user/change-password`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Password Updated");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error(error.response.data.data.error);
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const AddPhoneNumber = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.post(
        `${baseUrl}/user/add-phone-number`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Phone Number Added");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something went wrong, Try again in a moment");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const UpdatePhoneNumber = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.put(
        `${baseUrl}/user/update-phone-number`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Phone Number Updated");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something went wrong, Try again in a moment");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const AddAvatar = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.post(
        `${baseUrl}/user/add-avatar`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Profile Picture Added");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something went wrong, Try again in a moment");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const UpdateAvatar = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.put(
        `${baseUrl}/user/update-avatar`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Profile Picture Changed");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something went wrong, Try again in a moment");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const EnableSlots = async (id) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.delete(
        `${baseUrl}/admin/enable-timeslot/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Slot Enabled Successfully");
        setPostState({
          ...postState,
          response: data.message,
        });
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something went wrong, Try again in a moment");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const DisableSlots = async (formData) => {
    if (isAdmin) {
      setPostState({ ...postState, isLoading: true });
      try {
        const { data } = await axios.post(
          `${baseUrl}/admin/disable-timeslot`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.success) {
          setPostState({
            ...postState,
            response: data.message,
          });
          toast.success("Time Slot Disabled");
        }
      } catch (error) {
        setPostState({ ...postState, isError: true });
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          return toast.error(error?.response?.data?.data?.message);
        }
        toast.error("Something Went Wrong");
      } finally {
        setPostState({
          ...postState,
          isLoading: false,
        });
      }
    }
  };

  const CancelMultiSession = async (id) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.put(
        `${baseUrl}/admin/cancel-multisession/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Multi-Session Cancelled");
        fetchUserAppointments();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something Went Wrong");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };

  const PostWalkinAppointmentData = async (appointmentData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/admin/walk-in-appointment`,
        appointmentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        fetchUserAppointments();

        toast.success("Appointment Created Successfully");
        console.log(data.message);
      }
    } catch (error) {
      toast.error("Failed To Create Appointment, Retrying Please Wait");
      // PostWalkinAppointmentData();
    }
  };

  const GenerateReport = async (startDate, endDate) => {
    try {
      const { data } = await axios.post(`${baseUrl}/admin/generate-report`, {
        startDate,
        endDate,
      });
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      return url;
    } catch (error) {
      toast.error("Failed To Generate Report");
      console.error("Generate Report Error:", error);
      throw error;
    }
  };

  const UpdateWorkingHours = async (formData) => {
    try {
      const { data } = await axios.put(
        `${baseUrl}/admin/update-working-hours`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Working Hours Updated Successfully");
        fetchWorkingHours();
        return data.message; // Return the message for better handling
      }
    } catch (error) {
      toast.error("Something went wrong, Try again in a moment");
    }
  };

  const CheckSlotStatus = async (formData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/shop/check-slot-status`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        return { success: true }; // Return the message for better handling
      }
    } catch (error) {
      return { success: false, data: error?.response?.data?.data }; // Return the message for better handling
    }
  };

  return (
    <PostContext.Provider
      value={{
        ...postState,
        PostCouponData,
        UpdateCouponData,
        DeleteCouponData,
        UpdateRoomData,
        PostRoomData,
        PostProductData,
        PostAppointmentData,
        UpdateProductData,
        UpdateProductandServiceStatuses,
        UpdateProfile,
        UpdatePassword,
        UpdateUserAppointment,
        ReturnAppointmentPayment,
        DeleteProductData,
        DeleteRoomData,
        PostWalkinAppointmentData,
        VerifyCoupon,
        AddProductDiscount,
        RemoveProductDiscount,
        UpdateSingleProductDiscount,
        RemoveSingleProductDiscount,
        makeReturnPayment,
        AddPhoneNumber,
        UpdatePhoneNumber,
        AddAvatar,
        UpdateAvatar,
        EnableSlots,
        DisableSlots,
        CancelMultiSession,
        GenerateReport,
        UpdateWorkingHours,
        CheckSlotStatus,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};

export const usePostContext = () => useContext(PostContext);
